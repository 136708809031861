/**
 * @ignore
 * @private
 */
export default () => ({
  endpoints: {},
  cache: {},
  endpointsState: {},
  notReactiveEndpoints: {}
})
