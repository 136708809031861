/**
 * @ignore
 * @private
 */
export const CRUD_ACTIONS = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete'
}
